import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Get User
import getUser from "../../../utils/getUser";
import { Button, Card, Dropdown, Popconfirm, Space, Typography } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import routes from "../../routes";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = getUser();
  const [activeRouteName, setActiveRouteName] = useState("");
  const getHeaderTitle = () => {
    const path = location.pathname;

    const matchG = (route) => {
      try {
        const pathname = path.split("/")[1];
        const routename = route.path.split("/")[0];
        if (pathname === routename) return route;
      } catch (error) {
        console.log(error);
      }
    };
    const findTitle = routes
      .map((route) => matchG(route))
      .filter((item) => item)[0];
    setActiveRouteName(findTitle.name);
  };

  const logoutClick = () => {
    localStorage.removeItem("token");
    window.location.href = window.location.origin;
  };
  const items = [
    {
      key: "1",
      label: (
        <Button
          classNames="bg-red"
          onClick={() => navigate("/profile")}
          size="large"
          type="text"
          className="w-100"
        >
          Profile
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Popconfirm
          okText="Evet"
          placement="top"
          title="Uyarı"
          description="Çıkış yapmak istediğinize emin misiniz?"
          onConfirm={() => logoutClick()}
        >
          <Button size="large" type="text" className="w-100 text-danger">
            <LogoutOutlined />
            Çıkış Yap
          </Button>
        </Popconfirm>
      ),
    },
  ];
  useEffect(() => {
    getHeaderTitle();
  }, [location.pathname]);
  return (
    <div className="header" id="header">
      <Card bordered={"false"} className="h-100" bodyStyle={{ height: "100%" }}>
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div
                  className="dashboard_bar"
                  style={{ textTransform: "capitalize" }}
                >
                  <Typography.Title className="mb-0">
                    {activeRouteName}
                  </Typography.Title>
                </div>
              </div>
              <Dropdown placement="bottomRight" menu={{ items }}>
                <Button size="large" type="text">
                  {user.fullName}
                  <UserOutlined />
                </Button>
              </Dropdown>
            </div>
          </nav>
        </div>
      </Card>
    </div>
  );
};

export default Header;
