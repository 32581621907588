import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "./pages/Layout";

import routes from "./routes";
import protectedRoutes from "./routes/protectedRoutes";

import { Nav } from "./components";

import useToken from "./hooks/useToken";
export default function App() {
  const { token } = useToken();

  const setRoutes = () => {
    if (!token) {
      return protectedRoutes.map((route, key) => (
        <Route
          key={key}
          path={"/" + route.path}
          element={<route.component />}
        />
      ));
    }
    return routes.map((route, key) => (
      <Route
        key={key}
        path={"/" + route.path}
        element={
          <AuthController>
            <route.component />
          </AuthController>
        }
      />
    ));
  };
  return (
    <div id="main-wrapper" className="show">
      <BrowserRouter basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <InitialController>
                <Layout />
              </InitialController>
            }
          />
          {setRoutes()}
          <Route path="*" element={<Layout text="Sayfa bulunamadı!" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
function InitialController({ children }) {
  const { token } = useToken();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!token) {
        window.location.href = `${window.location.origin}/login`;
      } else {
        window.location.href = `${window.location.origin}/dashboard`;
      }
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  return children;
}
function AuthController({ children }) {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  return (
    <React.Fragment>
      <Nav />
      <div bordered={"false"} className="content-body" data-theme={theme}>
        <div className="container-fluid" data-theme={theme}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}
