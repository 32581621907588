import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Table
import AgGridTable from "../../components/AgGridTable";

// Expert Service
import { ContentService } from "../../../api";
import { Button, Tabs, message } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import {
  articleListColumn,
  videoListColumn,
  podcastListColumn,
} from "../../components/Columns";
import PodcastSVG from "../../../svgs/podcast.svg";
import VideoSVG from "../../../svgs/video.svg";
import ArticleSVG from "../../../svgs/article.svg";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/content";
const childrens = [
  {
    type: "PODCAST",
    columns: podcastListColumn,
    label: "Podcast",
    icon: PodcastSVG,
  },
  {
    type: "VIDEO",
    columns: videoListColumn,
    label: "Video",
    icon: VideoSVG,
  },
  {
    type: "ARTICLE",
    columns: articleListColumn,
    label: "Blog",
    icon: ArticleSVG,
  },
];
const TabsChildren = ({ item }) => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const onRowClick = (params) => {
    const pushUrl =
      "/content-detail/" +
      params.data.contentId +
      "/" +
      params.data.contentType;
    navigate(pushUrl);
  };
  const getRowData = async () => {
    try {
      const response = await ContentService.getContents(item.type);
      setRowData(response.data.data.contents);
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRowData();
  }, []);
  return (
      <AgGridTable
        loading={loading}
        onRowClicked={onRowClick}
        rowData={rowData}
        columnDefs={
          childrens.find((children) => children?.type === item.type).columns
        }
        sideBar={"filters"}
        rowHeight={40}
        headerChildren={
          <Button
            onClick={() => {
              navigate(`/new-content/${item.type}`);
            }}
            type="primary"
          >
            <PlusOutlined />
            Yeni {item.label} Ekle
          </Button>
        }
      />
      
  );
};
const Contents = () => {
  const dispatch = useDispatch();
  const activeKey = useSelector((state) => state?.content?.activeTab);

  const onChangeTab = (value) => {
    dispatch(setActiveTab(value));
  };

  return (
    <div className="h-100">
      <Tabs
        activeKey={activeKey}
        onChange={onChangeTab}
        items={childrens.map((item, index) => ({
          key: item.type,
          label: (
            <div className="d-flex align-items-center ">
              <img src={item.icon} alt="tab icon" className="mr-1" />
              {item.label}
            </div>
          ),
          children: <TabsChildren item={item} key={index} />,
        }))}
      />
    </div>
  );
};

export default Contents;
