import axiosInstance from "./axiosConfig";

const api = {
  getExpert: ({ id, params } = {}) => {
    let url = "/v1/experts";
    if (id) url += `/${id}`;
    return axiosInstance.get(url, {
      params: { size: 1000000 },
      ...params,
    });
  },
  createExpert: ({ body }) => {
    return axiosInstance.post("/v1/experts", body);
  },
  blockedExpert: (expertId) => {
    return axiosInstance.put(`/v1/experts/${expertId}/status/BLOCKED`);
  },
  activetedExpert: (expertId) => {
    return axiosInstance.put(`/v1/experts/${expertId}/status/ACTIVE`);
  },
  putExpert: ({ expertId, body } = {}) => {
    let url = "/v1/experts";
    url += `/${expertId}`;
    return axiosInstance.put(url, body);
  },
  getExpertTypes: () => {
    return axiosInstance.get("/v1/expert-types/expertises");
  },
  getExpertTitles: () => {
    return axiosInstance.get("/v1/expert-types/titles");
  },
  getExpertAvailability: ({ id, timeAvailability } = {}) => {
    let url = "/v1/experts";
    url += `/${id}`;
    url += `/days`;
    url += `/${timeAvailability}`;
    return axiosInstance.get(url);
  },
};
export default api;
